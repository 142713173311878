import "./Assets/CSS/global.css";
import "./Assets/CSS/app.css";
import "./Assets/CSS/SuperAdmin.css";
import MainRoutingFile from "./Navigation/index";
import { ConfigProvider } from "antd";
import moment from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import "moment/locale/en-gb"; 

function App() {
  return (
    <div className="App">
      <ConfigProvider  datePicker={{ generateConfig: momentGenerateConfig }}>
        <MainRoutingFile />
      </ConfigProvider>
    </div>
  );
}

export default App;
